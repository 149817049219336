export default function Copyright() {
  return (
    <div className="flex flex-col items-center pt-8">
      <div className="flex justify-between items-start w-full">
        <span className="text-text-black flex items-start text-sm whitespace-nowrap">
          <span className="relative -top-1/2 mr-1">©</span> RegTrail{" "}
          {new Date().getFullYear()}
        </span>
        <p className="text-center text-text-additional_2 text-sm">
          Designed by SDS
        </p>
      </div>
    </div>
  );
}

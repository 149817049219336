import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SVGMailIcon,
  SVGPasswordIcon,
  SVGShowPassword,
} from "../Images/svg/svg-icons";
import classNames from "classnames";
import useBlurOnScroll from "../../hooks/useBlurOnScroll";

export interface InputProps {
  className?: string;
  onChange?: any;
  value?: string;
  label?: string;
  isPassword?: boolean;
  isEmail?: boolean;
  isSamePassword?: boolean;
  error?: any;
  setTitleError?: Dispatch<SetStateAction<any>>;
  clearInput?: any;
  setClearInput?: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  isActive?: boolean;
  isReadOnly?: boolean;
  maxLength?: number;
}

const Input = ({
  className = "",
  onChange,
  value,
  label,
  isPassword,
  isEmail,
  isSamePassword = true,
  error,
  setTitleError,
  clearInput,
  setClearInput,
  placeholder,
  isActive,
  isReadOnly,
  maxLength,
}: InputProps) => {
  const inputRef = useRef<any>(null);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [passwordIcon, setPasswordIcon] = useState<any>(<SVGPasswordIcon />);

  useEffect(() => {
    if (clearInput && setClearInput) {
      inputRef.current.value = "";
      setClearInput(false);
    }
  }, [clearInput]);

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isActive]);

  useEffect(() => {
    if (isPasswordHidden) {
      setPasswordIcon(<SVGPasswordIcon />);
    } else {
      setPasswordIcon(<SVGShowPassword />);
    }
  }, [isPasswordHidden]);

  function ShowPassword() {
    setIsPasswordHidden(!isPasswordHidden);
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (!!value.trim().length || value === "") {
      onChange(value);
    }
    if (setTitleError) {
      setTitleError("");
    }
  }

  useBlurOnScroll(inputRef);

  return (
    <div className="flex flex-col">
      <label htmlFor={label} className="mb-3">
        {label}
      </label>
      <div className="relative">
        <input
          readOnly={isReadOnly}
          ref={inputRef}
          value={value}
          onChange={(event) => onInputChange(event)}
          type={isPassword && isPasswordHidden ? `password` : `text`}
          className={classNames(
            "p-2 pb-1 block w-full bg-main-input rounded-lg",
            !isSamePassword && "input-error",
            (isEmail || !isPasswordHidden) && "pl-9",
            className
          )}
          placeholder={placeholder}
          maxLength={maxLength || undefined}
          autoCapitalize={isEmail || isPassword ? "none" : "auto"}
          inputMode={isEmail ? "email" : undefined}
        />
        {isEmail && <SVGMailIcon />}
        {isPassword && <div onClick={ShowPassword}>{passwordIcon}</div>}
        {error && (
          <label className="text-red-500 absolute top-full text-xs mt-1">
            {error}
          </label>
        )}
      </div>
    </div>
  );
};

export default Input;

import axios from "axios";
import moment from "moment/moment";
import { showErrorMessage } from "../../hooks/showErrorMessage";

interface Params {
  pageParam?: number;
  topicsID: number[];
  geographiesID: number[];
  sortByDate: string;
  filter: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  typeTags: { label: string; value: string; condition: string };
  sourcesIds: number[];
  customTags: { id: number }[];
  isPublic: boolean;
  minRelevancyScore: number;
  maxRelevancyScore: number;
}

export const fetchArticlesNews = async ({
  pageParam,
  topicsID,
  geographiesID,
  sortByDate,
  filter,
  status,
  startDate,
  endDate,
  typeTags,
  isPublic,
  sourcesIds,
  customTags,
  minRelevancyScore,
  maxRelevancyScore,
}: Params) => {
  const formatStartDate = startDate
    ? moment(startDate).startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
    : null;
  const formatEndDate = endDate
    ? moment(endDate).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
    : null;

  const params = new URLSearchParams();

  if (topicsID?.length > 0) {
    topicsID.forEach((id: number) => params.append("topics_ids[]", String(id)));
  }
  if (geographiesID?.length > 0) {
    geographiesID.forEach((id: number) =>
      params.append("geographies_ids[]", String(id))
    );
  }

  if (typeTags && typeTags.value) {
    params.append("topicsCondition", typeTags.value === "any" ? "OR" : "AND");
    params.append(
      "geographyCondition",
      typeTags.value === "any" ? "OR" : "AND"
    );
  }

  if (sortByDate) {
    params.append("sort_by_date", sortByDate);
  }

  if (formatStartDate && formatEndDate) {
    params.append("start", formatStartDate);
    params.append("end", formatEndDate);
  }

  if (filter === "moderated") {
    params.append("is_moderated", "1");
  } else if (filter === "not_moderated") {
    params.append("is_moderated", "0");
  } else if (filter === "assigned") {
    params.append("is_assigned", "1");
  } else if (filter === "unassigned") {
    params.append("is_assigned", "0");
  }

  if (status !== "All") {
    params.append("status", status);
  }

  if (isPublic !== undefined) {
    params.append("is_public", Number(isPublic).toString());
    if (!isPublic) {
      params.append(
        "filter_by_from_relevancy",
        Number(minRelevancyScore).toString()
      );
      params.append(
        "filter_by_to_relevancy",
        Number(maxRelevancyScore).toString()
      );
      if (sourcesIds.length > 0) {
        sourcesIds.forEach((i: any) => params.append("feeds[]", String(i.id)));
      }
      if (customTags.length > 0) {
        customTags.forEach((i: any) =>
          params.append("custom_tags[]", String(i.id))
        );
      }
    }
  }

  if (pageParam) {
    params.append("page", String(pageParam));
  }

  const baseUrl =
    topicsID?.length > 0 ||
    geographiesID?.length > 0 ||
    sourcesIds?.length > 0 ||
    customTags?.length > 0
      ? "/api/quick-filter-articles"
      : "/api/user-filtered-articles";

  try {
    return await axios.get(`${baseUrl}?${params.toString()}`);
  } catch (error) {
    showErrorMessage({ error });
    throw error;
  }
};

import Image from "next/image";
import BigLogo from "../Images/BigRagTrailLogo.svg";
import React from "react";

export default function BigLogoArea() {
  return (
    <div className="h-[360px] md:h-auto w-full relative bg-bg-gray logins">
      <Image
        src={BigLogo}
        layout="fill"
        objectFit="cover"
        objectPosition="left"
        alt="Big RagTrail Logo"
        className="hidden md:block overflow-visible pl-10 z-10"
      />
      <object
        data={BigLogo.src}
        type="image/svg+xml"
        className="block md:hidden object-contain object-center h-full w-auto m-auto pt-8 pb-6"
        aria-label="Big RagTrail Logo"
      >
        <img
          src={BigLogo.src}
          alt="Big RagTrail Logo"
          className="block md:hidden object-contain object-center h-full w-auto m-auto pt-8 pb-6"
        />
      </object>
    </div>
  );
}

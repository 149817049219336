import { useEffect, useState, useCallback } from "react";
import { throttle } from "lodash";

export default function useIsMobile() {
  const [dimensions, setDimensions] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
    isMobile: typeof window !== "undefined" ? window.innerWidth <= 1024 : false,
  });

  const checkIfMobile = useCallback(
    throttle(() => {
      if (typeof window !== "undefined") {
        const currentWidth = window.innerWidth;
        const currentHeight = window.innerHeight;
        const currentIsMobile = currentWidth <= 1024;

        if (
          currentWidth !== dimensions.width ||
          currentHeight !== dimensions.height
        ) {
          setDimensions({
            width: currentWidth,
            height: currentHeight,
            isMobile: currentIsMobile,
          });
        }
      }
    }, 300),
    [dimensions]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfMobile);
      return () => {
        window.removeEventListener("resize", checkIfMobile);
      };
    }
  }, [checkIfMobile]);

  return { isMobile: dimensions.isMobile, height: dimensions.height };
}

export const localStorageKeys = {
  filter: "filter",
  articleStatus: "article-status",
  startDate: "start-date",
  endDate: "end-date",
  redirectTo: "redirect-to",
  minRelevancyScore: "news-min-relevancy",
  maxRelevancyScore: "news-max-relevancy",
  privateTab: "news-active-tab",
};

import React from "react";
import Spinner from "../../Images/svg/spinner.svg";
import Image from "next/image";

export const LoadingSpinner = () => {
  return (
    <Image
      width="40px"
      height="40px"
      src={Spinner}
      layout="fixed"
      objectFit="contain"
      objectPosition="center"
      alt="Spinner"
    />
  );
};

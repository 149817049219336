import { useForm } from "react-hook-form";
import Input from "../components/Inputs/Input";
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Copyright from "../components/Copyright/Copyright";
import { axiosAPI } from "../services";
import { useRouter } from "next/router";
import Link from "next/link";
import { NextSeo } from "next-seo";
import { profileContext } from "./_app";
import { LoadingSpinner } from "../components/Loading/icon-components/Spinner";
import { localStorageKeys } from "../services";
import { MENU_ROUTE } from "../utils/routes";
import BigLogoArea from "../components/AuthPage/BigLogoArea";
import useIsMobile from "../hooks/useIsMobile";

export default function Login() {
  const router = useRouter();
  const { isMobile } = useIsMobile();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (email.trim() && password.trim()) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [email, password]);

  const contextData = useContext(profileContext);
  async function onSubmit() {
    if (!!email.trim() && !!password.trim()) {
      try {
        setIsLoading(true);
        await axiosAPI
          .post("/api/auth/login", {
            email: email,
            password: password,
          })
          .then((res: any) => {
            contextData.setProfileInfo(res.data.data);
            localStorage.setItem(
              "access_token",
              res?.data?.token?.access_token
            );
            const profileOrganization = res?.data?.data?.organization?.name
              ? res.data.data.organization.name
              : "without organization";

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "login",
              company: profileOrganization,
              user_id: res?.data?.data.id,
            });

            if (!res?.data?.data?.is_onboard_finished) {
              router
                .push(MENU_ROUTE.ONBOARDING)
                .catch((e) => console.error(e, "private route error"))
                .then(() => setIsLoading(false));
              return;
            }

            const redirectTo = localStorage.getItem(
              localStorageKeys.redirectTo
            );

            if (redirectTo) {
              router.push(redirectTo).then(() => setIsLoading(false));
              localStorage.removeItem(localStorageKeys.redirectTo);
              return;
            }

            if (isMobile) {
              router
                .push(MENU_ROUTE.NEWS)
                .catch((e) => console.error(e, "private route error"))
                .then(() => setIsLoading(false));
            } else {
              router
                .push(MENU_ROUTE.DASHBOARD)
                .catch((e) => console.error(e, "private route error"))
                .then(() => setIsLoading(false));
            }
          });
      } catch (error: any) {
        if (error?.response?.data?.message) {
          setLoginError(error?.response?.data?.message);
        }
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <NextSeo title="Log in - RegTrail" />
      <div className="container flex flex-col-reverse md:flex-row justify-end md:justify-between md:items-stretch pr-0 bg-white max-w-none">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full md:max-w-[550px] box-border flex flex-col justify-between pt-6 pl-4 pr-4 md:pt-[107px] md:pr-12 md:pl-0 pb-8 flex-grow"
        >
          <div className="relative">
            <h1 className="mb-8 md:mb-16 text-center md:text-left">Log in</h1>
            <div className="relative">
              <div className="mb-8">
                <Input
                  label={"Email address"}
                  placeholder={"Type email address"}
                  value={email}
                  onChange={setEmail}
                  isEmail={true}
                  className="h-12"
                />
              </div>
              <div className="mb-8">
                <Input
                  label={"Password"}
                  placeholder={"Enter password"}
                  value={password}
                  onChange={setPassword}
                  isPassword={true}
                  className="h-12"
                />
              </div>
              <Link href={"/forgot-password"} passHref>
                <a className="text-text-main uppercase text-sm underline cursor-pointer w-auto">
                  Forgot the password
                </a>
              </Link>
            </div>
            <div className="relative md:pt-16 pt-6">
              <button
                type="submit"
                className={classNames(
                  "h-10 w-full rounded-3xl button-primary mb-6 p-2",
                  disableButton && "pointer-events-none button-disabled",
                  isLoading && "bg-white pointer-events-none"
                )}
              >
                <span className="mt-[2px] text-white">
                  {isLoading ? <LoadingSpinner /> : "Log in"}
                </span>
              </button>

              <p className="text-red-500 absolute -bottom-10 text-xs text-center w-full">
                {loginError}
              </p>
            </div>
          </div>
          <Copyright />
        </form>
        <BigLogoArea />
      </div>
    </>
  );
}
